export const routesConstants = {
    HOME: '/',
    SERVICES: '/services',
    ABOUT: '/about',
    TEAM: '/team',
    FORM: '/form',
    CERTIFICATES: '/certificates',
    CONTACT: '/contact',
    EMAIL: 'mailto:info@wagyufoodsandtech.com',
    TEL1: 'tel:08173888882',
    TEL2: 'tel:08173888882',
    PlayStore: 'https://play.google.com/store/apps/details?id=com.proteintrail',
    AppleStore: 'https://apps.apple.com/ng/app/proteintrail/id1620929935',
    ProteinTrail: 'https://proteintrail.com/',
}