import { useFormik } from "formik";
import { useState } from "react";

const Form = () => {
  const [submitted, setSubmitted] = useState(false);

  const formik = useFormik({
    initialValues: { name: "", email: "", file: null },
    onSubmit: async (values, { setSubmitting }) => {
      const formData = new FormData();
      formData.append("form-name", "contact");
      formData.append("name", values.name);
      formData.append("email", values.email);
      if (values.file) {
        formData.append("file", values.file);
      }

      try {
        const response = await fetch("/", {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          setSubmitted(true);
        } else {
          alert("Something went wrong. Please try again.");
        }
      } catch (error) {
        console.error("Submission error:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="max-w-md mx-auto p-6 bg-white shadow-md rounded-md">
      {submitted ? (
        <p className="text-green-600 font-bold text-center">
          Thank you! Your message has been sent.
        </p>
      ) : (
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          encType="multipart/form-data"
          onSubmit={formik.handleSubmit}
        >
          {/* Hidden Netlify Field */}
          <input type="hidden" name="form-name" value="contact" />

          {/* Honeypot (Spam Protection) */}
          <input type="hidden" name="bot-field" />

          <p>
            <label>
              Name
              <input
                type="text"
                name="name"
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                className="border p-2 rounded w-full"
              />
            </label>
          </p>

          <p>
            <label>
              Email
              <input
                type="email"
                name="email"
                required
                value={formik.values.email}
                onChange={formik.handleChange}
                className="border p-2 rounded w-full"
              />
            </label>
          </p>

          <p>
            <label>
              Upload File
              <input
                type="file"
                name="file"
                accept=".jpg,.png,.pdf"
                onChange={(event) =>
                  formik.setFieldValue("file", event.currentTarget.files ? event.currentTarget.files[0] : null)
                }
                className="border p-2 rounded w-full"
              />
            </label>
          </p>

          <p>
            <button
              type="submit"
              disabled={formik.isSubmitting}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              {formik.isSubmitting ? "Sending..." : "Send"}
            </button>
          </p>
        </form>
      )}
    </div>
  );
};

export default Form;